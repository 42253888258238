<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{ t("headings.edit_partner") }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div v-if="formElements" class="card grid p-4">
      <custom-form
        :formElements="formElements"
        formStyle="inline"
        @submitForm="updatePartner"
      />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import CustomForm from "../components/form/CustomForm.vue";
import { getISODate } from "../utils/dateUtils";
export default {
  components: { Breadcrumb, CustomForm },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const partner = computed(() => store.getters["partner/getPartner"]);
    const types = computed(() => store.state.partner.types);

    const formElements = ref(null);

    const setFormElements = () => {
      formElements.value = [
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "name",
          label: "name",
          rules: ["isRequired"],
          type: "text",
          value: partner.value.name,
          variable: "name",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "datepicker",
          id: "licence-date",
          label: "licence_validity",
          rules: ["isRequired"],
          textVariant: "white",
          value: getISODate(new Date(partner.value.licenceValidity)),
          variable: "licenceValidity",
          variant: "indigo",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "multiselect",
          id: "type-multiselect",
          label: "type",
          optionLabel: ["label"],
          options: types.value,
          rules: ["isRequired"],
          translatableLabel: "partner_types",
          value: partner.value.types,
          variable: "types",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "email",
          label: "email",
          rules: ["isRequired", "isEmail"],
          type: "text",
          value: partner.value.email,
          variable: "email",
        },
        {
          class:
            "col-span-12 md:col-span-10 lg:col-span-9 xl:col-span-7 sm:px-2",
          element: "input",
          id: "phone",
          label: "phone",
          rules: ["isRequired", "isPhoneNumber"],
          type: "text",
          value: partner.value.phone,
          variable: "phone",
        },
        {
          class: "col-span-12 text-right",
          element: "button",
          id: "submit-button",
          label: "save",
          textVariant: "white",
          type: "submit",
          variant: "indigo",
        },
      ];
    };

    onMounted(() => {
      if (!partner.value.id || partner.value.id != route.params.partnerId) {
        store.dispatch("partner/getPartner", route.params.partnerId);
      }
      if (!types.value || !types.value.length) {
        store.dispatch("partner/getTypes");
      }
      if (partner.value.id && types.value.length) {
        setFormElements();
      }
    });

    watch(
      () => partner.value,
      (partner) => {
        if (partner.id && types.value.length) {
          setFormElements();
        }
      }
    );

    watch(
      () => types.value,
      (types) => {
        if (types.length && partner.value.id) {
          setFormElements();
        }
      }
    );

    return {
      formElements,
      getISODate,
      partner,
      route,
      t,
      updatePartner: (data) =>
        store.dispatch("partner/updatePartner", { id: partner.value.id, data }),
    };
  },
};
</script>
